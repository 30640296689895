import React from 'react';
import Header from '../components/Header';
import Layout from '../components/layout';
import Adbutler from '../components/Adbutler';

const ThankyouGCR = () => {
  // sets adbutler zone id's based on param data
  let adButlerIds = ['290074', '290075', '290076', '290077', '290078'];

  return (
    <>
      <Header />
      <Layout isfluid={false}>
        <Adbutler adButlerIds={adButlerIds} />
      </Layout>
    </>
  );
};

export default ThankyouGCR;
