import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InnerHTML from 'dangerously-set-html-content';

const AdButler = ({ adButlerIds }) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const removeErrorGif = () => {
      setTimeout(() => {
        const imgs = document.querySelectorAll('img');
        const source = 'https://servedbyadbutler.com/error/blank.gif';
        if (imgs && imgs.length > 0) {
          Array.prototype.forEach.call(imgs, (img) => {
            if (img.src === source) {
              img.remove();
            }
          });
        }
      }, 2000);
    };

    const resizeIframes = async () => {
      const findIframes = document && document.getElementsByTagName('iframe');
      let zoneOverrides = [];
      try {
        const zoneOverrideFetch = await fetch(
          'https://s3.us-west-1.amazonaws.com/offers.printfingertech.net/js/zone-height-overrides.json'
        );
        zoneOverrides = await zoneOverrideFetch.json();
      } catch (error) {
        console.log(error);
      }

      const checkZoneOverride = (elementId) => {
        const zoneId = parseInt(elementId.split('_')[1]);
        const zoneObj = zoneOverrides.filter((obj) => obj.zoneId === zoneId);
        return zoneObj;
      };

      if (findIframes) {
        const adButlerInterval = setInterval(() => {
          Array.prototype.forEach.call(findIframes, (element) => {
            if (element.id.includes('placement')) {
              try {
                const iframeBody = element?.contentWindow?.document?.body;

                // gets the rates offer class element
                const ratesOffer = iframeBody.childNodes[0].querySelector(
                  '.rates-offer'
                );

                let scrollHeight = iframeBody?.scrollHeight + 40;

                // sets the height of the iframe to 0 if the rates-offer element is set to display: none
                if (ratesOffer && ratesOffer !== null) {
                  const compStyles =
                    typeof window !== `undefined` &&
                    window.getComputedStyle(ratesOffer);

                  if (compStyles && compStyles.display === 'none') {
                    scrollHeight = iframeBody?.scrollHeight;
                    element.style.height = `${scrollHeight}px`; // should equal 0 every time but not hardcoding it just inc
                  }
                }

                if (iframeBody.scrollHeight < 20) {
                  element.style.height = '1px';
                  if (element.style.height === '1px') {
                    element.style.marginBottom = '0';
                  } else {
                    element.style.marginBottom = '1.45rem';
                  }
                } else if (
                  scrollHeight &&
                  `${scrollHeight}px` !== element.style.height
                ) {
                  // set override here
                  if (zoneOverrides.length > 0) {
                    const viewportWidth =
                      typeof window !== `undefined` && window.innerWidth;

                    // returns the zone override object if it exists
                    const overrideObj = checkZoneOverride(element.id);

                    if (overrideObj.length > 0) {
                      if (viewportWidth < 768) {
                        element.style.height = `${overrideObj[0].mobile}px`;
                      } else {
                        element.style.height = `${overrideObj[0].height}px`;
                      }
                    } else {
                      element.style.height = `${scrollHeight}px`;
                    }
                  } else {
                    console.log('no zone overrides');
                    element.style.height = `${scrollHeight}px`;
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(adButlerInterval);
        }, 10000);
      }
    };

    (async () => {
      const adButlerScriptArray = await adButlerIds.map((id, index) => {
        return `<!-- ${index} [asyncbeta] -->
        <script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
        <div class="plc${id}"></div>
        <script type="text/javascript">
        var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
        var abkw = window.abkw || '';
        var plc${id} = window.plc${id} || 0;
        (function(){
        var divs = document.querySelectorAll(".plc${id}:not([id])");
        var div = divs[divs.length-1];
        div.id = "placement_${id}_"+plc${id};
        AdButler.ads.push({handler: function(opt){ AdButler.register(169214, ${id}, [730,300], 'placement_${id}_'+opt.place, opt); }, opt: { place: plc${id}++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
        })();
        </script>`;
      });

      const adString = await adButlerScriptArray.join('');
      const replaceHttp = await adString.replace(/http:\/\//gi, 'https://');

      setHtml(replaceHttp);
      resizeIframes();
      removeErrorGif();
    })();
  }, [adButlerIds]);

  return (
    <div className="container showBlocks" id="adButlerShowAll">
      <div id="adbutler">
        <div id="adbutlerContainer">
          <InnerHTML html={html} />
        </div>
      </div>
    </div>
  );
};

AdButler.propTypes = {
  adButlerIds: PropTypes.array,
};

export default AdButler;
